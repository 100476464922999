<template>
  <div class="profileConnectionModal">
    <base-heading
      type="2"
      modifiers="center"
      class="profileConnectionModal__title"
    >
      {{ $t('medisanaModalStepActivateAccount.title') }}
    </base-heading>

    <p class="profileConnectionModal__body">
      {{ $t('medisanaModalStepActivateAccount.body') }}
    </p>

    <div class="profileConnectionModal__iconWrapper">
      <font-awesome-icon
        v-bind:icon="['fas', 'envelope-open-text']"
        class="profileConnectionModal__icon"
      />
    </div>

    <base-button
      modifiers="primary"
      class="profileConnectionModal__button"
      v-on:click="$emit('NEXT_STEP')"
    >
      {{ $t('medisanaModalStepActivateAccount.nextButtonLabel') }}
    </base-button>

    <base-button
      modifiers="secondary"
      class="profileConnectionModal__button"
      v-on:click="$emit('STEP_BACK')"
    >
      {{ $t('medisanaModalStepActivateAccount.backButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__body {
  @include paragraph;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding: 0 5rem;
  }
}

.profileConnectionModal__iconWrapper {
  margin: 30px auto;
  text-align: center;
}

.profileConnectionModal__icon {
  font-size: 80px;
  color: $color-beige--dark;
}

.profileConnectionModal__button {
  display: block;
  margin: 0 auto 1rem auto;

  &:last-child {
    margin: 0 auto;
  }
}
</style>

<template>
  <div class="profileConnectionModal">
    <base-heading
      type="2"
      modifiers="center"
      class="profileConnectionModal__title"
    >
      {{ $t('medisanaModalStepAppInformation.title') }}
    </base-heading>

    <p class="profileConnectionModal__body">
      {{ $t('medisanaModalStepAppInformation.body') }}
    </p>

    <div class="profileConnectionModal__videoContainer">
      <div class="profileConnectionModal__videoWrapper">
        <video
          v-bind:src="require('@/assets/integrations/medisana/2.mp4')"
          disableremoteplayback=""
          playsinline
          autoplay
          muted="muted"
          loop="loop"
          class="profileConnectionModal__video"
        />
      </div>
    </div>

    <base-button
      modifiers="primary"
      class="profileConnectionModal__button"
      v-on:click="$emit('NEXT_STEP')"
    >
      {{ $t('medisanaModalStepAppInformation.nextButtonLabel') }}
    </base-button>

    <base-button
      modifiers="secondary"
      class="profileConnectionModal__button"
      v-on:click="$emit('STEP_BACK')"
    >
      {{ $t('medisanaModalStepAppInformation.backButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__body {
  @include paragraph;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding: 0 5rem;
  }
}

.profileConnectionModal__videoContainer {
  margin: 30px 0;
  text-align: center;
}

.profileConnectionModal__videoWrapper {
  display: inline-block;
  position: relative;;
  padding: 5px 5px 0 5px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  border: 4px solid $color-beige;
  border-bottom: none;
  overflow: hidden;
}

.profileConnectionModal__video {
  height: 344px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.profileConnectionModal__button {
  display: block;
  margin: 0 auto 1rem auto;

  &:last-child {
    margin: 0 auto;
  }
}
</style>

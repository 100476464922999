<template>
  <div class="profileConnectionModal__modal">
    <base-heading
        type="2"
        modifiers="center"
        class="profileConnectionModal__title"
    >
      {{ $t('champModalStepConnect.title') }}
    </base-heading>

    <div class="profileConnectionModal__content">
      <p
          class="profileConnectionModal__body"
          v-html-safe="$t('champModalStepConnect.body1')"
      />
      <a
          class="profileConnectionModal__connectionInfoBodyLink"
          v-on:click="onShowChampInfoModal"
          v-on:click.prevent="() => {}"
      >
        Meer info
      </a>
      <p
          class="profileConnectionModal__body"
          v-html-safe="$t('champModalStepConnect.body2')"
      />

      <div
          class="profileConnectionModal__buttons"
      >
        <base-button
            modifiers="secondary"
            class="profileConnectionModal__button"
            v-on:click="$emit('close')"
        >
          {{ $t('champModalStepConnect.closeButtonLabel') }}
        </base-button>

        <base-button
            modifiers="primary"
            class="profileConnectionModal__button"
            v-bind:show-spinner="isLoading"
            v-on:click="onChampConnect"
        >
          {{ $t('champModalStepConnect.connectButtonLabel') }}
        </base-button>
      </div>

      <base-alert
          v-if="savingError"
          class="champModal__alert"
      >
        {{ savingError }}
      </base-alert>
    </div>
  </div>
</template>

<script>
import {defineAsyncComponent, markRaw} from "vue";
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import BaseAlert from "@/components/BaseAlert.vue";
import {mapActions} from "vuex";
import {ModalBus} from "@/eventBus";

const ChampInfoModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/ChampInfoModal' /* webpackChunkName: "champInfoModal" */),
}));

export default {
  components: {
    BaseAlert,
    BaseHeading,
    BaseButton,
  },

  data() {
    return {
      isLoading: true,
      savingError: '',
    };
  },

  async created() {
    this.isLoading = false;
  },

  methods: {
    ...mapActions('member', [
      'champConnect',
    ]),

    async onChampConnect() {
      this.isLoading = true;

      try {
        const response = await this.champConnect();
        window.location.href = response.redirect_url;

        this.savingError = '';
      } catch (error) {
        this.savingError = this.$t('common.oopsTryAgain') + error;
      } finally {
        setTimeout(() => {
          if (this.isLoading) {
            this.isLoading = false;

            if (!this.savingError) {
              this.$emit('close');
            }
          }
        }, 2000);
      }
    },

    onShowChampInfoModal() {
      ModalBus.emit('open', {
        component: ChampInfoModal,
        modifiers: 'champ',
      });
    }
  },

};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 4rem auto 1rem auto;
  width: 100%;

  .profileConnectionModal__button {
    display: inline-block;
    margin: 0;
  }
}

.profileConnectionModal__content {
  margin: rem(32px) 0 0;
}

.profileConnectionModal__connectionInfoBodyLink {
  text-decoration: underline;
  color: $color-green;
  cursor: pointer;
}

</style>

<template>
  <div class="baseAlert">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseAlert {
  margin: rem(16px) 0 0 0;
  padding: rem(20px);
  border-radius: 10px;
  color: $color-white;
  background: #db4444;
}
</style>

<template>
  <div class="profileConnectionModal">
    <base-heading
      type="2"
      modifiers="center"
      class="profileConnectionModal__title"
    >
      {{ $t('medisanaModalStepConnect.title') }}
    </base-heading>

    <p class="profileConnectionModal__body">
      {{ $t('medisanaModalStepConnect.body') }}
    </p>

    <div class="profileConnectionModal__logos">
      <img
        v-bind:src="require('@/assets/integrations/medisana/medisana.png')"
        v-bind:srcset="`${require('@/assets/integrations/medisana/medisana@2x.png')} 2x`"
        class="profileConnectionModal__logo"
      />

      <font-awesome-icon
        v-bind:icon="['fas', 'arrow-alt-right']"
        class="profileConnectionModal__arrowIcon"
      />

      <img
        v-bind:src="require('@/assets/integrations/medisana/fc.png')"
        v-bind:srcset="`${require('@/assets/integrations/medisana/fc@2x.png')} 2x`"
        class="profileConnectionModal__logo"
      />
    </div>

    <p
      v-if="isLoading"
      class="profileConnectionModal__note"
    >
      {{ $t('medisanaModalStepConnect.note') }}
    </p>

    <a
      v-bind:disabled="!medisanaLink || isLoading"
      v-bind:href="medisanaLink"
      target="_blank"
      rel="noopener noreferrer"
      class="profileConnectionModal__link"
    >
      <base-button
        modifiers="primary"
        v-bind:show-spinner="isLoading"
      >
        {{ $t('medisanaModalStepConnect.connectButtonLabel') }}
      </base-button>
    </a>

    <base-button
      modifiers="secondary"
      class="profileConnectionModal__button"
      v-on:click="$emit('STEP_BACK')"
    >
      {{ $t('medisanaModalStepConnect.backButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';
import getToken from '@/utils/getToken';
import parseJWT from '@/utils/parseJWT';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },

  data() {
    return {
      medisanaLink: '',
      isLoading: true,
    };
  },

  async created() {
    const token = getToken();

    if (token) {
      const userId = parseJWT(getToken()).sub;

      this.$echo
        .private(`user.${userId}`)
        .listen('.vitadock.success', () => {
          this.isLoading = false;
          this.$emit('NEXT_STEP');
        });
    }

    const response = await this.medisanaLogin();
    this.medisanaLink = response.redirect_url;
  },

  methods: {
    ...mapActions('member', ['medisanaLogin']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__body,
.profileConnectionModal__note {
  @include paragraph;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding: 0 rem(80px);
  }
}

.profileConnectionModal__logos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
}

.profileConnectionModal__logo {
  display: block;
  border-radius: 10px;
  border: 1px solid $color-beige;
}

.profileConnectionModal__arrowIcon {
  margin: 0 20px;
  font-size: rem(24px);
  color: $color-beige--dark;
}

.profileConnectionModal__note {
  margin: 0 0 30px 0;
}

.profileConnectionModal__link {
  display: block;
  margin: 0 0 rem(16px) 0;
  text-align: center;
}

.profileConnectionModal__button {
  display: block;
  margin: 0 auto rem(16px) auto;

  &:last-child {
    margin: 0 auto;
  }
}
</style>

<template>
  <div class="profileConnectionModal">
    <base-heading
      type="2"
      modifiers="center"
      class="profileConnectionModal__title"
    >
      {{ $t('medisanaModalStepCreateAccount.title') }}
    </base-heading>

    <p class="profileConnectionModal__body">
      {{ $t('medisanaModalStepCreateAccount.body') }}
    </p>

    <img
      v-bind:src="require('@/assets/integrations/medisana/medisana.png')"
      v-bind:srcset="`${require('@/assets/integrations/medisana/medisana@2x.png')} 2x`"
      class="profileConnectionModal__logo"
    />

    <a
      href="https://cloud.vitadock.com/signup"
      target="_blank"
      rel="noopener noreferrer"
      class="profileConnectionModal__link"
      v-on:click="$emit('NEXT_STEP')"
    >
      <base-button modifiers="primary">
        {{ $t('medisanaModalStepCreateAccount.nextButtonLabel') }}
      </base-button>
    </a>

    <base-button
      modifiers="secondary"
      class="profileConnectionModal__button"
      v-on:click="$emit('SKIP_STEP')"
    >
      {{ $t('medisanaModalStepCreateAccount.skipButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__body {
  @include paragraph;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding: 0 5rem;
  }
}

.profileConnectionModal__logo {
  display: block;
  margin: 40px auto;
  border-radius: 10px;
  border: 1px solid $color-beige;
}

.profileConnectionModal__link {
  display: block;
  margin: 0 0 1rem 0;
  text-align: center;
}

.profileConnectionModal__button {
  display: block;
  margin: 0 auto 1rem auto;

  &:last-child {
    margin: 0 auto;
  }
}
</style>

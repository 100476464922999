<template>
  <div
    v-bind:class="{
      [blockName]: true,
      [`${blockName}--${type}`]: true,
      [modifierClasses]: true,
    }"
  >
    <slot />
  </div>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers([
      'center',
      'centerMobile',
      'centerDesktop',
    ]),
  ],

  props: {
    type: {
      type: [Number, String],
      required: true,
      validator: value => {
        return !isNaN(parseInt(value, 10));
      },
    },
  },

  data() {
    return {
      blockName: 'baseHeading',
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseHeading {
  margin: 0 0 rem(8px) 0;
  color: $color-text;

  &--1 {
    @include heading-1;
  }

  &--2 {
    @include heading-2;
  }

  &--3 {
    @include heading-3;
  }

  &--4 {
    @include heading-4;
    margin: 0 0 rem(16px) 0;
  }

  &--5 {
    @include heading-5;
  }

  &--center {
    text-align: center;
  }

  &--centerMobile {
    @include mobile {
      text-align: center;
    }
  }

  &--centerDesktop {
    @include desktop {
      text-align: center;
    }
  }
}
</style>

<template>
  <div class="profile-connection-modal">
    <medisana-modal-step-create-account
      v-if="currentStep === 1"
      v-on:NEXT_STEP="currentStep += 1"
      v-on:SKIP_STEP="currentStep += 2"
    />

    <medisana-modal-step-activate-account
      v-if="currentStep === 2"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-download-app
      v-if="currentStep === 3"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-app-login
      v-if="currentStep === 4"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-app-information
      v-if="currentStep === 5"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-app-bluetooth
      v-if="currentStep === 6"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-app-setup
      v-if="currentStep === 7"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-connect
      v-if="currentStep === 8"
      v-on:STEP_BACK="currentStep -= 1"
      v-on:NEXT_STEP="currentStep += 1"
    />

    <medisana-modal-step-success
      v-if="currentStep === 9"
      v-on:close="$emit('close')"
    />
  </div>
</template>

<script>
import MedisanaModalStepCreateAccount from '@/components/profile/MedisanaModalStepCreateAccount';
import MedisanaModalStepActivateAccount from '@/components/profile/MedisanaModalStepActivateAccount';
import MedisanaModalStepDownloadApp from '@/components/profile/MedisanaModalStepDownloadApp';
import MedisanaModalStepAppLogin from '@/components/profile/MedisanaModalStepAppLogin';
import MedisanaModalStepAppInformation from '@/components/profile/MedisanaModalStepAppInformation';
import MedisanaModalStepAppBluetooth from '@/components/profile/MedisanaModalStepAppBluetooth';
import MedisanaModalStepAppSetup from '@/components/profile/MedisanaModalStepAppSetup';
import MedisanaModalStepConnect from '@/components/profile/MedisanaModalStepConnect';
import MedisanaModalStepSuccess from '@/components/profile/MedisanaModalStepSuccess';

export default {
  components: {
    MedisanaModalStepCreateAccount,
    MedisanaModalStepActivateAccount,
    MedisanaModalStepDownloadApp,
    MedisanaModalStepAppLogin,
    MedisanaModalStepAppInformation,
    MedisanaModalStepAppBluetooth,
    MedisanaModalStepAppSetup,
    MedisanaModalStepConnect,
    MedisanaModalStepSuccess,
  },

  data() {
    return {
      currentStep: 1,
    };
  },
};
</script>

<template>
  <div class="profileConnectionModal">
    <base-heading
      type="2"
      modifiers="center"
      class="profileConnectionModal__title"
    >
      {{ $t('medisanaModalStepDownloadApp.title') }}
    </base-heading>

    <p class="profileConnectionModal__body">
      {{ $t('medisanaModalStepDownloadApp.body') }}
    </p>

    <div class="profileConnectionModal__appLogoContainer">
      <img
        v-bind:src="require('@/assets/integrations/medisana/medisana-app.png')"
        v-bind:srcset="`${require('@/assets/integrations/medisana/medisana-app@2x.png')} 2x`"
        class="profileConnectionModal__appLogo"
      />

      <div class="profileConnectionModal__appLogoTitle">
        {{ $t('medisanaModalStepDownloadApp.subTitle') }}
      </div>
    </div>

    <div class="profileConnectionModal__appDownloadButtons">
      <a
        href="https://apps.apple.com/nl/app/vitadock/id766441568"
        target="_blank"
        rel="noopener noreferrer"
        class="profileConnectionModal__appDownloadButton"
      >
        <img
          v-bind:src="require('@/assets/integrations/medisana/apple-app-store-download.png')"
          v-bind:srcset="`${require('@/assets/integrations/medisana/apple-app-store-download@2x.png')} 2x`"
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=de.medisana.vitadockplus"
        target="_blank"
        rel="noopener noreferrer"
        class="profileConnectionModal__appDownloadButton"
      >
        <img
          v-bind:src="require('@/assets/integrations/medisana/google-play-store-download.png')"
          v-bind:srcset="`${require('@/assets/integrations/medisana/google-play-store-download@2x.png')} 2x`"
        />
      </a>
    </div>

    <base-button
      modifiers="primary"
      class="profileConnectionModal__button"
      v-on:click="$emit('NEXT_STEP')"
    >
      {{ $t('medisanaModalStepDownloadApp.nextButtonLabel') }}
    </base-button>

    <base-button
      modifiers="secondary"
      class="profileConnectionModal__button"
      v-on:click="$emit('STEP_BACK')"
    >
      {{ $t('medisanaModalStepDownloadApp.backButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseHeading,
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileConnectionModal__body {
  @include paragraph;
  text-align: center;

  @media (min-width: $breakpoint-md) {
    padding: 0 5rem;
  }
}

.profileConnectionModal__appLogoContainer {
  margin: 30px 0;
  text-align: center;
}

.profileConnectionModal__appLogoTitle {
  @include label;
  margin: -20px 0 0 0;
  color: $color-text-dimmed;
}

.profileConnectionModal__appDownloadButtons {
  margin: 0 0 50px 0;
  text-align: center;

  @include desktop {
    display: flex;
    justify-content: center;
  }
}

.profileConnectionModal__appDownloadButton {
  display: block;
  margin: 0 0 10px 0;

  @include desktop {
    margin: 0 10px 0 0;
  }

  &:last-child {
    margin: 0;
  }
}

.profileConnectionModal__button {
  display: block;
  margin: 0 auto 1rem auto;

  &:last-child {
    margin: 0 auto;
  }
}
</style>
